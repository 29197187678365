/**
 * Copyright (C) Zorgcampus - All Rights Reserved
 * 
 * This source code is protected under international copyright law.  All rights
 * reserved and protected by the copyright holders.
 * This file is confidential and only available to authorized individuals with the
 * permission of the copyright holders.  If you encounter this file and do not have
 * permission, please contact the copyright holders and delete this file.
 */
import React, { useEffect, useRef, useContext } from "react";
import { Message } from "./Message";
import Input from "./Input";
import { ChatContext } from "./ChatContext";
// import { ChatLoader } from "./ChatLoader";



export default function ChatRoom({ socketError, isOpen, chatId, roomName }) {
  
  const scrollRef = useRef(null);
  
  const { state, dispatch } = useContext(ChatContext);
  const { messages } = state; 

    
  useEffect(() => {
    if (messages.length < 1) {
      const greet = `Welkom op Zorgcampus! Hoe kan ik u helpen?`
      dispatch({  type: 'MESSAGES', payload: {text: greet,} });
    }
  }, []) //eslint-disable-line

  
  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  }, [messages]);
  
  
  return (
    <div className="lg:col-span-2 lg:block">
      <div className="w-full">

        <div className="relative w-full p-3 overflow-y-auto h-[65vh] md:h-[75vh] bg-white border-b border-gray-200 dark:bg-gray-900 dark:border-white">
          <ul className="space-y-2">
            {messages?.map((message) => (
              <div key={message.createdAt} ref={scrollRef}>
                <Message 
                  user={message.user} 
                  text={message.text} 
                  createdAt={message.createdAt} 
                  chatId={chatId}
                />
              </div>
            ))}
          </ul>
        </div>

        <Input 
          socketError={socketError}
          isOpen={isOpen}
          roomName={roomName}
          chatId={chatId}
        />
      </div>
    </div>
  );
}
