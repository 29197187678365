/**
 * Copyright (C) Zorgcampus - All Rights Reserved
 * 
 * This source code is protected under international copyright law.  All rights
 * reserved and protected by the copyright holders.
 * This file is confidential and only available to authorized individuals with the
 * permission of the copyright holders.  If you encounter this file and do not have
 * permission, please contact the copyright holders and delete this file.
 */

import ChatRoom from './ChatRoom';
import { ChatContext } from './ChatContext';
import { wsService } from "./utils";
import { useContext, useState, useEffect } from "react";
import { FaMinus } from 'react-icons/fa';
import { BsFillChatLeftDotsFill } from 'react-icons/bs';


const ZorgChatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [socketError, setSocketError] = useState(null)
  const { state, dispatch } = useContext(ChatContext);
  const { user, roomName } = state; 

  
  useEffect(() => {
    wsService.connect(`${roomName}`);
    wsService.registerMessageListener({ dispatch, setSocketError });
    return () => wsService.disconnect(); // Cleanup on unmount
  }, [roomName, dispatch]);

  useEffect(() => {
    dispatch({ type: 'SET_FUNCTIONS', payload: { wsService } });      
    const localUser = localStorage.getItem('zorgChatbotUser') || null;
    if (localUser) {
      dispatch({ type: 'SET_USER', payload: localUser });
    }else {
      dispatch({ type: 'SET_USER', payload: user });
      localStorage.setItem('zorgChatbotUser', user);
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    // send isOpen to parent window, which is on the host site
    window.parent.postMessage({ chatOpen: isOpen }, '*');
  }, [isOpen]);

  return (
    <div className="fixed bottom-4 right-4 mx-1 md:bottom-6 ">
      <div
        className={`bg-[#B01865] ${isOpen ? "hidden" : "rounded-full p-2 cursor-pointer"}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <BsFillChatLeftDotsFill 
          className='w-12 h-12 text-white p-2'
        />
      </div>
            
        {isOpen && (
            <div>
                <div className="flex flex-row bg-[#2E608D] rounded-t-xl justify-between p-3 text-white">
                  <p className={`text-2xl font-bold ml-5`}>Zorg Chatbot</p>
                  <FaMinus 
                    className='mr-5 cursor-pointer w-10 h-7' 
                    onClick={() => setIsOpen(!isOpen)} 
                  />
                </div>
                { user ? 
                  <ChatRoom 
                    socketError={socketError}
                    setSocketError={setSocketError}
                    isOpen={isOpen}
                    roomName={roomName}
                  />
                  : null 
                }
            </div>
        )}
    </div>
  );
};



export default ZorgChatbot;