/**
 * Copyright (C) Zorgcampus - All Rights Reserved
 * 
 * This source code is protected under international copyright law.  All rights
 * reserved and protected by the copyright holders.
 * This file is confidential and only available to authorized individuals with the
 * permission of the copyright holders.  If you encounter this file and do not have
 * permission, please contact the copyright holders and delete this file.
 */
import { getTimeAgo } from "./utils";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}


export const Message = ({ user, text, createdAt })=> {
  return (
    <li
      className={classNames(
        user === "01H2WYF18JXWGF2MX084MCFTGM" ? "justify-start" : "justify-end",
        "flex"
      )}
    >
      <div>
        <div
          className={classNames(
            user === "01H2WYF18JXWGF2MX084MCFTGM"
              ? "bg-[#2E608D] dark:bg-[#2E608D] text-white"
              : "text-gray-700 dark:text-gray-400 bg-white border border-gray-200 shadow-md dark:bg-gray-900 dark:border-gray-700",
            "relative max-w-xl px-4 py-2 rounded-lg shadow"
          )}
        >
          <span className="block font-normal">{text}</span>
        </div>
        <span className="block text-sm text-gray-700 dark:text-gray-400">
          {getTimeAgo(createdAt)}
        </span>
      </div>
    </li>
  );
}
