/**
 * Copyright (C) Zorgcampus - All Rights Reserved
 * 
 * This source code is protected under international copyright law.  All rights
 * reserved and protected by the copyright holders.
 * This file is confidential and only available to authorized individuals with the
 * permission of the copyright holders.  If you encounter this file and do not have
 * permission, please contact the copyright holders and delete this file.
 */

import { useContext, useState, useRef, useEffect } from "react";
import { ChatContext } from "./ChatContext";
import Loader from "./Loader";
import Spiner from "./Spiner";
import { VscSend } from 'react-icons/vsc';



export default function Input({ socketError, isOpen, chatId, roomName }) {
  const { state, dispatch } = useContext(ChatContext);
  const { user, wsService } = state;
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false)

  const inputRef = useRef(null);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    wsService.registerMessageListener({ setLoading });
  }, [wsService]);


  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const newMessage = message.trim();
      setMessage("");
      setLoading(true);
      if (newMessage) {
        dispatch({ type: 'MESSAGES', payload: { text: newMessage, user } });
        await wsService.sendMessage({
          message: newMessage,
          user_type: "customer"
        });
      }
    } catch (error) {
      console.log("error: ", error);
    } 
  };
  




  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="flex items-center justify-between w-full h-fu p-5 bg-white border-b border-gray-200 dark:bg-gray-900 dark:border-gray-700">
          {socketError ? (
            <Loader socketError={socketError} />
            ) : (
              <>
                <input
                  ref={inputRef}
                  type="text"
                  placeholder="Schrijf een bericht"
                  className="block w-full p-2 mx-3 outline-none bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={message}
                  onChange={(event) => setMessage(event.target.value)}
                  required
                />
                <button type="submit" className="rounded-full p-2 cursor-pointer bg-[#2E608D]">
                  {loading ? <Spiner /> : <VscSend className='w-8 h-8 text-white'/> }
                </button>
              </>
            )}
        </div>
      </form>
    </div>
  );
}
