/**
 * Copyright (C) Zorgcampus - All Rights Reserved
 * 
 * This source code is protected under international copyright law.  All rights
 * reserved and protected by the copyright holders.
 * This file is confidential and only available to authorized individuals with the
 * permission of the copyright holders.  If you encounter this file and do not have
 * permission, please contact the copyright holders and delete this file.
 */
import React, { createContext, useReducer } from 'react';
import { ulid } from 'ulid';


const INITIAL_STATE = {
  // user will be store in localstorage and will be used to identify 
  // the user on the server side too
  user: ulid().toLowerCase(),
  messages: [],
  roomName: ulid().toLowerCase(),
};


const chatReducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER':      
      return { ...state, user: action.payload };

    case 'SET_FUNCTIONS':      
      return { ...state, ...action.payload };

    case 'MESSAGES':
      const { user, text } = action.payload;
      const messages = [
        ...state.messages, {
          createdAt: new Date().toISOString(),
          user: user ?? '01H2WYF18JXWGF2MX084MCFTGM',
          text,
        }
      ];
      
      return { ...state, messages };

    default:
      return state;
  }
};

export const ChatContext = createContext({
  state: INITIAL_STATE,
  dispatch: () => undefined,
});


export const ChatContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(chatReducer, INITIAL_STATE);

  return (
    <ChatContext.Provider value={{ state, dispatch }}>
      {children}
    </ChatContext.Provider>
  );
};