/**
 * Copyright (C) Zorgcampus - All Rights Reserved
 * 
 * This source code is protected under international copyright law.  All rights
 * reserved and protected by the copyright holders.
 * This file is confidential and only available to authorized individuals with the
 * permission of the copyright holders.  If you encounter this file and do not have
 * permission, please contact the copyright holders and delete this file.
 */



function generateUniqueString() {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
};


const getTimeAgo = (createdAt) => {
  const currentTime = new Date();
  const createdTime = new Date(createdAt);
  const elapsed = Math.floor((currentTime.getTime() - createdTime.getTime()) / 1000); 

  const timeUnits = [
    { unit: 'dag', threshold: 86400 },
    { unit: 'uur', threshold: 3600 },
    { unit: 'minuut', threshold: 60 },
    { unit: 'seconde', threshold: 5 },
  ];

  for (const unit of timeUnits) {
    if (elapsed >= unit.threshold) {
      const value = Math.floor(elapsed / unit.threshold);
      
      // Als de verstreken tijd meer dan een dag is, retourneer in het formaat "Maand Afkorting Datum, Jaar"
      if (unit.unit === 'dag' && value >= 1) {
        const months = ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'];
        return `${months[createdTime.getMonth()]} ${createdTime.getDate()}, ${createdTime.getFullYear()}`;
      }
      
      return `${value} ${unit.unit}${value > 1 ? 'en' : ''} geleden`;
    }
  }

  return 'Zojuist';
};



class WebSocketService {
  
  constructor() {
    if (WebSocketService.instance) {
      return WebSocketService.instance;
    }
    WebSocketService.instance = this;

    this.ws = null;
    this.roomName = null;
    this.callbacks = {};
    this.reconnectInterval = null;
    this.reconnectAttempts = 0; // Track the number of reconnect attempts
    this.maxReconnectAttempts = 10; // Maximum number of reconnect attempts
    this.messageQueue = []; // Queue to store messages while disconnected
  }

  // Connect to the WebSocket
  connect(roomName) {
    this.roomName = roomName;
    this.ws = new WebSocket(
      `wss://2l57dx2q-8000.euw.devtunnels.ms/ws/chat/${roomName ?? this.roomName}/`
    );

    this.ws.onopen = () => {
      this.ifFunction(this.callbacks['setSocketError'], null);
      this.reconnectAttempts = 0; // Reset reconnect attempts on successful connection
      this.clearReconnectInterval();
      // Send message queue to sendMessage method
      if(this.messageQueue.length >= 1) this.sendMessage(this.messageQueue);
    };

    this.ws.onmessage = (event) => {
      const { message } = JSON.parse(event.data);
      if (message && this.callbacks['dispatch']) {
        // This displays the message in the chat
        this.ifFunction(this.callbacks['dispatch'], { type: 'MESSAGES', payload: { text: message } });

        // setLoading(false);
        this.ifFunction(this.callbacks['setLoading'], false);

      }
    };

    this.ws.onerror = (error) => {
      console.error('WebSocket Error:', error);
    };

    this.ws.onclose = () => {
      this.setReconnectInterval(); // Reconnect logic
    };
  }

  // Send a message through the WebSocket
  sendMessage(message) {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      // If message is an array, send each message separately
      if (Array.isArray(message)) {
        while (this.messageQueue.length > 0) {
          const message = this.messageQueue.shift();
          this.ws.send(JSON.stringify(message));
        }
        return;
      }
      this.ws.send(JSON.stringify(message));
      
    } else {
      // If the socket is not open, add the message to the queue
      this.messageQueue.push(message);
    }
  }

  // fetch messages from server
  fetchMessages(roomName) {
    this.sendMessage({
      command: "fetch_messages",
      roomName: roomName
    });
  }
    

  // Set an interval to attempt reconnection
  setReconnectInterval() {
    this.clearReconnectInterval();

    // Check if the maximum number of attempts has been reached
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      // Calculate the reconnect delay using exponential backoff
      // Here, I've added a variable reconnectAttempts to track the number of 
      // reconnect attempts and a maxReconnectAttempts to set a limit on 
      // how many times the service will try to reconnect. The delay between 
      // reconnect attempts starts at 1 second and doubles with each failure, 
      // up to a maximum of 30 seconds.
      // This strategy provides a more graceful handling of connection failures
      // and avoids overwhelming the server with connection requests.

      const reconnectDelay = Math.min(1000 * (2 ** this.reconnectAttempts), 30000); // Max delay of 30 seconds


      this.reconnectInterval = setTimeout(() => this.connect(this.roomName), reconnectDelay);
      this.reconnectAttempts += 1;
      this.ifFunction(this.callbacks['setSocketError'], "Verbinden...");
    } else {
      this.ifFunction(this.callbacks['setSocketError'], "Verbinding mislukt. Vernieuw om het opnieuw te proberen.");
      
    }
  }

  // Clear the reconnect interval
  clearReconnectInterval() {
    if (this.reconnectInterval) {
      clearTimeout(this.reconnectInterval);
      this.reconnectInterval = null;
    }
  }

  // Disconnect and clean up
  disconnect() {
    this.clearReconnectInterval();
    if (this.ws) {
      this.ws.close();
    }
  }

  // Check if external function is a function and call it
  ifFunction(callback, ...args) {
    if (typeof callback === 'function') {
      callback(...args);
    }
  }

  registerMessageListener(callbacksObject) {
    this.callbacks = {...this.callbacks, ...callbacksObject}
  }
};

const wsService = new WebSocketService();


export {
  generateUniqueString,
  getTimeAgo,
  wsService,
};
